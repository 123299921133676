// eslint-disable-next-line
const template = [
    // {
    //     title: "",
    //     image: require(""),
    //     imdb: "",
    // }
]

export const nowPlaying = [
    {
        title: "Grease (1978)",
        image: require("../src/assets/grease-1978.webp"),
        imdb: "https://www.imdb.com/title/tt0077631/",
    },
    {
        title: "Jaws (1975)",
        image: require("../src/assets/jaws-1975.webp"),
        imdb: "https://www.imdb.com/title/tt0073195/",
    },
]

export const toBeDetermined = [
    {
        title: "To Be Determined",
        image: require("./assets/to-be-determined.jpg"),
        imdb: "",
    },
    {
        title: "To Be Determined",
        image: require("../src/assets/to-be-determined.jpg"),
        imdb: "",
    }
]

// eslint-disable-next-line
export const comingSoon = [
    // {
    //     title: "",
    //     image: require(""),
    //     imdb: "",
    // },
    // {
    //     title: "",
    //     image: require(""),
    //     imdb: "",
    // }
]
